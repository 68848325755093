import React, { Fragment } from "react";
import About from "../components/About";
import Events from "../components/Events";
import Footer from "../components/Footer";
import GetEdified from "../components/GetEdified";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import NewsletterCTA from "../components/NewsletterCTA";
import PartnershipCTA from "../components/PartnershipCTA";
import AboutHero from "./../assets/hero/heroBg1.jpg";

const Home = () => {
  return (
    <Fragment>
      <div>
        <div className="bg-[#F4FBFF] h-fit p-4 lg:px-28 lg:py-12 w-full">
          <Navbar />
          <Hero />
        </div>
        <div className="mt-32 p-4 lg:px-28 lg:py-12">
          <Events />
        </div>
      </div>
      <div
        className="mt-32 px-4 py-12 lg:px-8 lg:py-12 lg:h-[100vh]"
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 70, 0.5), rgba(0, 0, 0, 0.2)), url(${AboutHero})`,
          backgroundSize: "cover",
          // backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
        }}
      >
        <div className="w-full flex flex-col lg:flex-row gap-56 lg:gap-14 h-full">
          <div className="self-start w-full lg:w-1/2">
            <About
              title={"Our Vision"}
              description="We envision men in their multitude saved, trained and sent out for the work of the ministry."
            />
          </div>
          <div className="self-end w-full lg:w-1/2">
            <About
              title={"The Mission"}
              margin="ml-auto"
              description={
                <ol>
                  <li className="mt-3">
                    1. To have men come to the saving knowledge of Christ Jesus
                    by the preaching of the gospel.
                  </li>
                  <li className="mt-3">
                    2. To have men trained for the work of ministry by
                    consistent and sound teaching of the word.
                  </li>
                  <li className="mt-3">
                    3. To record men sent out to duplicate the former in the
                    lives of others.
                  </li>
                </ol>
              }
            />
          </div>
        </div>
      </div>
      {/* <div className="mt-32 p-4 lg:px-28 lg:py-12">
        <Testimonials />
      </div> */}
      <div className="mt-32 p-4 lg:px-28 lg:py-12">
        <GetEdified />
      </div>
      <div className="mt-32">
        <PartnershipCTA />
      </div>
      <NewsletterCTA />
      <Footer />
    </Fragment>
  );
};

export default Home;
